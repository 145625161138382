import React from "react";

/**
  import Wifi from "@material-ui/icons/Wifi";
  import WifiOff from "@material-ui/icons/WifiOff";
  import useNetwork from "./hooks/useNetwork"; 
 
  const Test = () => {
    const networkState = useNetwork();

    const { online,since,downLink,downLinkMax,effectiveType,rtt,saveData,type,} = networkState;
    return (
      <Container maxWidth="md">
        <div className={classes.container}>
          {online ? (
            <Wifi className={iconActiveStyles} />
          ) : (
            <WifiOff className={iconInactiveStyles} />
          )}
          <div>
            <h1>Network Stats:</h1>
            <NetworkInfoTable data={networkState} />
          </div>
        </div>
      </Container>
    );
  };
 **/

function getNetworkConnection() {
  return (
    navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection ||
    null
  );
}

function getNetworkConnectionInfo() {
  const connection = getNetworkConnection();
  if (!connection) {
    return {};
  }

  return {
    rtt: connection.rtt,
    type: connection.type,
    saveData: connection.saveData,
    downLink: connection.downLink,
    downLinkMax: connection.downLinkMax,
    effectiveType: connection.effectiveType,
  };
}

function useNetworkHook() {
  const [networkStatus, setNetworkStatus] = React.useState(() => {
    return {
      since: undefined,
      online: navigator.onLine,
      ...getNetworkConnectionInfo(),
    };
  });
  
  React.useEffect(() => {
    const handleOnline = () => { setNetworkStatus((prevState) => ({ ...prevState, online: true , since: new Date().toString(), })); };
    const handleOffline= () => { setNetworkStatus((prevState) => ({ ...prevState, online: false, since: new Date().toString(), })); };
    const handleConnectionChange = () => { setNetworkStatus((prevState) => ({ ...prevState, ...getNetworkConnectionInfo(), })); };
    
    window.addEventListener("online" , handleOnline);
    window.addEventListener("offline", handleOffline);

    const connection = getNetworkConnection();
    connection?.addEventListener("change", handleConnectionChange);

    // called when the component is unmounted (prevent memory-leaks)
    return () => {
      window.removeEventListener("online" , handleOnline);
      window.removeEventListener("offline", handleOffline);
      connection?.removeEventListener("change", handleConnectionChange);
    };

  }, []);

  return networkStatus;
}

//export default useNetworkHook;

/**
 * CONTEXT PROVIDER
 **/
 const Context = React.createContext();

 export const NetworkProvider = ({ children }) => { 
  const networkStatus = useNetworkHook();
  return (
     <Context.Provider value={networkStatus}>{children}</Context.Provider>
   );
 }
  
 /* Hook to use store in any functional component */
 export const useNetworkStatus = () => React.useContext(Context);
 
 /* HOC to inject store to any functional or class component */
 export const withNetworkStatus = (Component) => (props) => {
   return <Component {...props} networkStatus={useNetworkStatus()} />;
 };
 