import React from "react";
import { ethers } from "ethers";

//============================================================================//
import debuglog from "debug/consolelog"; const clog = debuglog("useEthersNetwork");
//============================================================================//

function useEthersNetworkHook() {
  const [networkObject, setNetworkObject] = React.useState(null);

  React.useEffect(() => {
    let _provider;

    /*
    const options = {
      keepAlive: true,
      timeout: 20000, // milliseconds,
      headers: [{name: 'Access-Control-Allow-Origin', value: '*'},],
      withCredentials: false,
      //agent: {http: http.Agent(...), baseUrl: ''}
    };
    */
    // A Web3Provider wraps a standard Web3 provider, which is what MetaMask injects as window.ethereum into each page
    _provider = new ethers.providers.Web3Provider(window?.ethereum, "any"); // connect to injected wallet
    
    //const _provider = new ethers.providers.JsonRpcProvider(); // connect to http://localhost:8545
    
    /*
    let _prcUrl
    _prcUrl = "https://rpc01.dogechain.dog";
    _prcUrl = "https://dogechain.ankr.com";
    _prcUrl = "https://bsc-dataseed1.binance.org";
    _prcUrl = "https://rpc.goerli.mudit.blog/";
    _provider = new ethers.providers.JsonRpcProvider(_prcUrl); // connect to rpc
    */

    /*
    _provider.getBalance("ricmoo.firefly.eth").then((balance) => {
      console.log("BALANCE::", balance.toString());
    });
    */

    //if (!_provider) setNetworkObject(null);

    // check network
    _provider?.getNetwork().then(_network => {
      // extract chainid
      const _chainId = _network?.chainId;

      if (networkObject?.chainId !== _chainId) {
        const newObject = {
          chainId: _chainId,
          network: _network,
          provider: _provider,
        }
        clog("setNetworkObject: ", newObject, "; old Object:", networkObject);
        setNetworkObject(newObject);
      }
    });

    /*
    _provider?.ready.then(_status => {
      console.log("_status: ", _status);
      setNetworkObject(prevState => ({ ...prevState, ready: _status}));
    });
    */

    // change network. if network not registered in metamask: try to register (ask user to confirm add)
    //await metamaskExtension.changeOrAddNetwork(chainProvider);

    //const handleOnline = () => { setNetworkObject((prevState) => ({ ...prevState, online: true , since: new Date().toString(), })); };
    const handleAccountsChanged = () => {}
    const handleChainChanged = () => {}
    const handleNetworkChanged = (network, oldNetwork) => {
      if (network.chainId !== oldNetwork.chainId) {
        setNetworkObject({
          chainId: network?.chainId,
          network: network,
          provider: _provider,
        });    
      }
      clog("new chainId:", network.chainId, "; old chainId:", oldNetwork.chainId);
    }

    networkObject?.provider?.on('accountsChanged', handleAccountsChanged);
    networkObject?.provider?.on('chainChanged'   , handleChainChanged);
    networkObject?.provider?.on('network'        , handleNetworkChanged);
    
    //interface ConnectInfo {chainId: string;}
    //provider.on('connect', handler: (connectInfo: ConnectInfo) => void);
      
    //interface ProviderRpcError extends Error {message: string;code: number;data?: unknown; }
    //provider.on('disconnect', handler: (error: ProviderRpcError) => void);
      
    //window.addEventListener("online" , handleOnline);
    //window.addEventListener("offline", handleOffline);

    // called when the component is unmounted (prevent memory-leaks)
    return () => {
      //window?.removeListener('online', handleOnline);
      //window?.removeListener('offline', handleOffline);
    };

  }, [networkObject]);

  return networkObject;
}

/**
 * CONTEXT PROVIDER
 **/
 const Context = React.createContext();

 // wrap components with context
 // <EthersNetworkContextProvider><App/></EthersNetworkContextProvider>
 export const EthersNetworkContextProvider = ({ children }) => { 
  return (<Context.Provider value={useEthersNetworkHook()}>{children}</Context.Provider>);
 }
  
 /* to use in all wrapped component */
 // import {useEthersNetwork} from "useEthersNetwork";
 // const networkObject = useEthersNetwork();
 export const useEthersNetwork = () => React.useContext(Context);
 