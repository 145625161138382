// https://mobx.js.org/defining-data-stores.html
import React from "react";
import { makeAutoObservable, action, toJS, /*autorun, runInAction, computed, */ } from "mobx";
import { makePersistable, stopPersisting, clearPersistedStore, /*PersistStoreMap, , getPersistedStore, isHydrated, isPersisting, hydrateStore, startPersisting, pausePersisting*/ } from "mobx-persist-store";

import "./globalConfig";

//============================================================================//
import debuglog from "debug/consolelog"; const clog = debuglog("appStore");
//============================================================================//

export default class AppStoreClass {
  appVersion = 1;
  storeVersion = 1;
  storeIsHydrated = false;  stopStore() { stopPersisting(this); } clearStore() { clearPersistedStore(this) }
  debugWait = false;        set_debugWait = action(value => { this.debugWait = value; })
  globalSpinnerText = "";   set_globalSpinnerText(value)    { this.globalSpinnerText = value }
  openSpinner  = action(value  =>  this.globalSpinnerText = value);
  closeSpinner = action(value  =>  this.globalSpinnerText = "");

  /**
   * PERSISTENT PROPERTIES
   **/ 
  userAccount   = {}; set_userAccount(value) { this.userAccount = value; } // set_userAccount = action(value => { this.userAccount = value; });
  walletAddress = ""; set_walletAddress = action(value => this.walletAddress = value);
  walletName    = ""; set_walletName = action(value => this.walletName = value);

  // list of all persistent properties
  persistedProperties() {
    return [
      "userAccount",
      "walletAddress",
      "walletName",
    ];
  }

  /**
   * NON-PERSISTENT PROPERTIES
   **/ 
  provider = null; set_provider(value) { this.provider = value; } // set_provider = action(value => { this.provider = value; });
  network  = null; set_network(value) { this.network = value; } 

  signer   = null; set_signer = action(value => { this.signer = value; });

  // list of all non-persistent properties
  temporaryProperties() {
    return [
      "appVersion",
      "storeVersion",
      "provider",
      "network",
      "signer",
    ];
  }



  get providerIsConnected() { return (this.provider && this.network && this.network?.chainId > 0); }






  /**
   * INIT to default-values before hydration
   **/ 
  defaultInit() {
    this.userAccount = {};
    this.walletAddress = "";
    this.walletName = "unnamedWallet";
  }

  /**
   * change some persisted properties after hydration
   **/ 
  forceInit() {

  }

  // 
  toJSON() {
    const obj = { persisted: {}, temporary: {}, };
    this.persistedProperties().forEach(item => obj["persisted"][item] = toJS(this[item]));
    this.temporaryProperties().forEach(item => obj["temporary"][item] = toJS(this[item]));
    return obj;
  }
  
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    // -> All own properties become observable.
    // -> All getters become computed.
    // -> All setters become action.
    // -> All functions on prototype become autoAction.
    // -> All generator functions on prototype become flow. (Note that generator functions are not detectable in some transpiler configurations, if flow doesn't work as expected, make sure to specify flow explicitly.)
    // -> Members marked with false in the overrides argument will not be annotated. For example, using it for read only fields such as identifiers.
    
    this.defaultInit();

    // save to store
    makePersistable(this, {
      name: "AppStorePersited",
      properties: this.persistedProperties(), // properties to persist
    })
    .then(action(peristedStore => { // fires when store is hydrated
      this.storeIsHydrated = peristedStore.isHydrated;
      this.forceInit();
    }));

    /*
    // manual save full store
    const storeName = "AppStorePersited2"
    const storedJson = localStorage.getItem(storeName);
    if (storedJson) Object.assign(this, JSON.parse(storedJson));
    autorun(() => {
      localStorage.setItem(storeName, JSON.stringify(this))
    })
    */
    /*
    const properties = ["counter", "color"]
    const storedJson = localStorage.getItem(name)
    console.log(storedJson)
    if (storedJson) {
      this.state = JSON.parse(storedJson)
    }
    const value = toJS(_this)
    localStorage.setItem(name, JSON.stringify(pick(this, properties)))
    */

  } // of constructor


  /**
   * CONNECT METHODS
   **/ 
  DEBUG_connectUserAccount() { this.connectUserAccount("test-token", "test-address", "test-username"); }

  connectUserAccount(signToken, address, username, providerHandle, provider, signer) {
    const data = {
      token: signToken,
      username: username,
      address: address,
      provider: provider,
      handle: providerHandle,
      signer: signer,
    };

    this.set_userAccount(data);
  }

  disconnectUserAccount() { 
    this.connectUserAccount();
  }

  get isValidUserAccount() { 
    try {
      const { token, username, address } = this.userAccount || {};

      if (!token)    throw new Error("connection: invalid token");
      if (!address ) throw new Error("connection: invalid address");
      if (!username) throw new Error("connection: invalid username");

      /*
      const verifySignTokenData = Web3Token.verify(token); //, { domain: 'com.geohash.domain' });
      const { address: signTokenAddress, body: signTokenBody } = verifySignTokenData;
      clog("verify.token", signTokenAddress, signTokenBody); // -> body: {uri: 'https://port-3000-nodejs-xdeltax.preview.codeanywhere.com/', nonce: '91229530', web3-token-version: '2', issued-at: '2022-03-11T09:38:28.083Z', expiration-time: '2022-03-12T09:38:28.000Z'}
      if (!signTokenAddress || signTokenAddress.length !== 42 || signTokenAddress !== address) throw new Error("invalid login-token address");
      //const { "expiration-time": expTime, "issued-at": issuedAt, nonce, uri } = signTokenBody || {}
      //clog("verify.token", expTime, issuedAt, nonce, uri); 
      */
      return true;
    } catch(error) {
      clog("ERROR.userStore.isAuthUser", error.message);
      return false;
    }
  }


} // of class

//export default AppStoreClass;
//export default new AppStoreClass();


/**
 * CONTEXT PROVIDER
 * https://codingislove.com/setup-mobx-react-context/
 **/
const AppStoreContext = React.createContext();

export const AppStoreProvider = ({ children, store }) => (
  <AppStoreContext.Provider value={store}>{children}</AppStoreContext.Provider>
);
 
/* Hook to use store in any functional component */
export const useAppStore = () => React.useContext(AppStoreContext);

/* HOC to inject store to any functional or class component */
export const withAppStore = (Component) => (props) => {
  return <Component {...props} appStore={useAppStore()} />;
};
