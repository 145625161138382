import { configure as configureMobX } from "mobx";
import { configurePersistable } from "mobx-persist-store";

// https://mobx.js.org/configuration.html#configuration
configureMobX({
  enforceActions: "always",
  computedRequiresReaction: true,
  reactionRequiresObservable: true,
  observableRequiresReaction: true,
  disableErrorBoundaries: false,
});

// https://github.com/quarrant/mobx-persist-store#global-configuration
configurePersistable({
  storage: window.localStorage,
  removeOnExpiration: true, // default: true
  stringify: true, // default: true
  debugMode: false, // default: false
  //expireIn: 86400000, // default: never
}, {
  delay: 200, 
  fireImmediately: false, 
});