import React from 'react';
import ReactDOM from 'react-dom/client';
import { observer } from 'mobx-react-lite';

import CssBaseline from '@mui/material/CssBaseline'
import Typography from "@mui/material/Typography";

import reportWebVitals from './reportWebVitals';

import { Buffer } from 'buffer'; // polyfill: required for QR-code in WalletConnectConnector

// hooks
import AppStoreClass, { AppStoreProvider } from "store/AppStore";
import { NetworkProvider } from "hooks/useNetworkStatus"; 
import { EthersNetworkContextProvider } from "web3/hooks/useEthersNetwork"; 

import { WagmiConfig, createClient, chain, configureChains } from 'wagmi';

import { InjectedConnector } from 'wagmi/connectors/injected';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
//import { alchemyProvider } from 'wagmi/providers/alchemy'

import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
//import { publicProvider } from 'wagmi/providers/public';
//import { getDefaultProvider } from 'ethers'

import { ConnectKitProvider } from "connectkit";

import ErrorBoundary from "./helpers/ErrorBoundary"; 

//============================================================================//
import debuglog from "debug/consolelog"; const clog = debuglog("INDEX");
//============================================================================//

// polyfill Buffer for client (required for QR-code in WalletConnectConnector)
if (!window.Buffer) { window.Buffer = Buffer }


//*
//* public RPC endpoints (provided by pokt.network)
//*
//clog("************", publicProvider());
const prcEndpointsPocket = {
  Avalanche       : "https://avax-rpc.gateway.pokt.network",            //	43114	AVAX	https://cchain.explorer.avax.network
  BinanceSmartChain:"https://bsc-rpc.gateway.pokt.network",             //	56	  BNB	  https://bscscan.com
  Ethereum        : "https://eth-rpc.gateway.pokt.network",             //	1	    ETH	  https://etherscan.io
  EthereumGoerli  : "https://eth-goerli-rpc.gateway.pokt.network",      //	5	    ETH	  https://goerli.etherscan.io
  EthereumKovan   : "https://eth-kovan-rpc.gateway.pokt.network",       //	42	  ETH	  https://kovan.etherscan.io
  EthereumRinkeby : "https://eth-rinkeby-rpc.gateway.pokt.network",     //	4	    ETH	  https://rinkeby.etherscan.io
  EthereumRopsten : "https://eth-ropsten-rpc.gateway.pokt.network",     //	3	    ETH	  https://ropsten.etherscan.io
  Evmos           : "https://evmos-rpc.gateway.pokt.network",           //	9001	EVMOS	https://evm.evmos.org
  Fantom          : "https://fantom-rpc.gateway.pokt.network",          //	250	  FTM	  https://ftmscan.com
  FUSE            : "https://fuse-rpc.gateway.pokt.network",            //	122	  FUSE	https://explorer.fuse.io
  GnosisChain     : "https://gnosischain-rpc.gateway.pokt.network",     //	100	  xDAI	https://blockscout.com/poa/xdai
  Harmony         : "https://harmony-0-rpc.gateway.pokt.network",       //	1666600000	ONE	https://explorer.harmony.one
  IoTeX           : "https://iotex-rpc.gateway.pokt.network",           //	4689	IOTX	https://iotexscan.io
  NEAR            : "https://near-rpc.gateway.pokt.network",            //		    NEAR	https://www.nearscan.org
  OKXChain        : "https://okc-rpc.gateway.pokt.network",             //	66	  OKT	  https://www.oklink.com/en/okc
  Optimism        : "https://optimism-rpc.gateway.pokt.network",        //	10	  ETH	  https://optimistic.etherscan.io
  Osmosis         : "https://osmosis-rpc.gateway.pokt.network",         //		    OSMO	https://www.mintscan.io/osmosis
  PocketNetwork   : "https://pokt-rpc.gateway.pokt.network",            //		    POKT	https://explorer.pokt.network
  Polygon         : "https://poly-rpc.gateway.pokt.network",            //	137	  MATIC	https://polygonscan.com
  PolygonMumbai   : "https://polygon-mumbai-rpc.gateway.pokt.network",  //	80001	MATIC	https://mumbai.polygonscan.com
  SwimmerMainnet  : "https://avax-cra-rpc.gateway.pokt.network",        //	73772	TUS	  https://explorer.swimmer.network
}
/*
const rpcEndpointPocket1Mio = {
  IoTeX    : "https://iotex-mainnet.gateway.pokt.network/v1/lb/6176f902e19001003499f492", // 1 mio requests per day
  Avalanche: "https://avax-mainnet.gateway.pokt.network/v1/lb/605238bf6b986eea7cf36d5e/ext/bc/C/rpc", // 1 mio requests per day
  Binance  : "https://bsc-mainnet.gateway.pokt.network/v1/lb/6136201a7bad1500343e248d", // 1 mio requests per day
  Ethereum : "https://eth-rpc.gateway.pokt.network", // 10 mio requests per day
  Polygon  : "https://poly-rpc.gateway.pokt.network", // 5 mio requests per day
}
*/


//*
//* custom chains (configuration parameters)
//*
const ethpMainnetChain = {
  id: 10001,
  testnet: false,
  name: 'ETHW-mainnet',
  network: 'ethw-mainnet',
  nativeCurrency: { decimals: 18, name: 'Ethereum POW', symbol: 'ETHW', },
  //multicall: {address: '0xca11bde05977b3631167028862be2a173976ca11', blockCreated: 12063863 },
  rpcUrls: { default: 'https://mainnet.ethereumpow.org', },
  blockExplorers: { default: { name: 'ethwScan', url: 'https://mainnet.ethwscan.com' }, },
};

const ethpTestnetChain = {
  id: 10002,
  testnet: true,
  name: 'ETHW-iceberg-testnet',
  network: 'ethw-testnet',
  nativeCurrency: { decimals: 18, name: 'Ethereum POW', symbol: 'ETHW', },
  //multicall: {address: '0xca11bde05977b3631167028862be2a173976ca11', blockCreated: 12063863 },
  rpcUrls: { default: 'https://iceberg.ethereumpow.org', },
  blockExplorers: { default: { name: 'ethwScan', url: 'http://iceberg.ethwscan.com' }, },
};

const ethfChain = {
  id: 513100,
  testnet: false,
  name: 'EthereumFair',
  network: 'ethf-mainnet',
  nativeCurrency: { decimals: 18, name: 'Ethereum Fair', symbol: 'ETHF', },
  //ens: {address: '0xca11bde05977b3631167028862be2a173976ca11' },
  //multicall: {address: '0xca11bde05977b3631167028862be2a173976ca11', blockCreated: 12063863 },
  rpcUrls: { 
    default: 'https://rpc.etherfair.org', 
    public : "https://rpc.etherfair.org"
  },
  blockExplorers: { default: { name: 'ethfExplorer', url: 'https://explorer.etherfair.org' }, },
};

const customChains = configureChains(
  [ chain.localhost, chain.hardhat,               // local-node: 1337, hardhat-node: 31337 
    chain.mainnet, chain.rinkeby, chain.ropsten,  // integrated chain ethereum
    chain.polygon, chain.polygonMumbai,           // integrated chain polygon / matic
    ethpMainnetChain, ethpTestnetChain,           // custom chain ethereumPOW
  ], 
  [ jsonRpcProvider({
      rpc: (chain) => {
        //if (chain.id !== ethpTestnetChain.id) return null
        if (chain.id === ethpMainnetChain.id) return { http: chain.rpcUrls.default };
        if (chain.id === ethpTestnetChain.id) return { http: chain.rpcUrls.default };
        return { http: chain.rpcUrls.default }
        //return null;
      },
    }),
    //alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_API_KEY }), // required for walletconnect
    //infuraProvider({ apiKey: process.env.REACT_APP_INFURA_API_KEY }), // required for walletconnect
    //publicProvider(), // the publicProvider (ethereum only) ensures that your chains always have an RPC URL to fall back on (in case Alchemy does not support the chain).
  ]
);

const { chains, provider, webSocketProvider } = customChains;

const wagmiClient = createClient({
  autoConnect: true,
  connectors: [ // chains: to pass to your connector(s)
    new MetaMaskConnector      ({ chains }),
    new InjectedConnector      ({ chains, options: { name: 'Injected', shimDisconnect: true, }, }),
    new WalletConnectConnector ({ chains, options: { qrcode: false, }, }),
    new CoinbaseWalletConnector({ chains, options: { appName: 'wagmi', }, }),
  ], 
  provider: provider, // if a user has their wallet connected to a chain that is unsupported by your app, the provider will use the first chain listed in the chains array.
  //provider: getDefaultProvider(),
  webSocketProvider: webSocketProvider,
})


const GlobalSpinner = React.lazy(() => import('./helpers/GlobalSpinner'));

const App = React.lazy(() => import('./App'));
//const App = React.lazy(() => import('./AppTEST'));

// same screen as defined in ./public/index.html <- className="loading-screen" and src="./loading-screen.png"
const LoadingScreen = ({infotext}) => ( 
  <div className="loading-screen" style={{ overflow: "hidden", }}>
    <img width="60%" src="./loading-screen.png" alt=" loading application ... " />
    <Typography variant="h6" style={{ position: "absolute", bottom: 0, width: "100%", textAlign: "center", }}>{infotext}</Typography>
  </div>
);


const appStore = new AppStoreClass();

/*
const dAppConfig = {
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls: {
    [Mainnet.chainId]: 'https://mainnet.infura.io/v3/62687d1a985d4508b2b7a24827551934',
  },
}
*/

const RenderApplication = observer(() => {
  // start-sequence
  //  immediately: show animated loading-image from "./public/index.html"
  //  load reactjs from server
  //  create appStore and load data from persistant storage
  //  show (<LoadingScreen />) from render() until store hydrated
  //  init app, init mobx-store, register event-listeners, load app-state from persistent, query database for updates
  //  show (<LoadingScreen />) from Lazy / Suspense until <AppRouter> is loaded from server
  
  React.useEffect(() => {
    // called when the component is unmounted (prevent memory-leaks)
    return () => {
      return () => appStore.stopStore(); // Called when the component is unmounted
    };
  }, []);

  ////////////////////////////////////////////////////////////////
  // LOAD STORE: RENDER LOADING-SCREEN UNTIL STORE IS HYDRATED
  ////////////////////////////////////////////////////////////////
  if (!appStore.storeIsHydrated) {
    return (<LoadingScreen infotext="hydrating store ..." />);
    //return (<LoadingSpinner text="hydrating appStore" />);
  }

  clog("AFTER HYDRATION: appStore.toJSON: ", appStore.toJSON());
  

  /*
  ////////////////////////////////////////////////////////////////
  // FORCE INIT STORE-VALUES
  ////////////////////////////////////////////////////////////////
  if (!appStore?.walletAddress) {
    clog("FORCE STORE 1: ", appStore?.walletAddress);
    appStore?.set_walletAddress("0xa56F6ab0A795F164f57213A9cf7Ba678233125Da");
    clog("FORCE STORE 2: ", appStore?.walletAddress);
  } else {
    clog("NOFORCE STORE: ", appStore?.walletAddress);
  }
  */

  /*
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  appStore.set_debugWait(true); 
  delay(1000).then( observer( () => { appStore.set_debugWait(false); } ));
  if (appStore.debugWait) {
    return (<LoadingScreen infotext="waiting for godot ..." />);
    //return (<LoadingSpinner text="hydrating appStore" />);
  }
  */

  ////////////////////////////////////////////////////////////////
  // RENDER APP
  ////////////////////////////////////////////////////////////////
  return (
    <React.Suspense fallback={<LoadingScreen infotext="rendering app ..."/>}>
      <WagmiConfig client={wagmiClient}>
        <ConnectKitProvider>
          <EthersNetworkContextProvider>
            <NetworkProvider>
              <AppStoreProvider store={appStore}>
                <CssBaseline />
                <ErrorBoundary>
                  <GlobalSpinner infotext={appStore?.globalSpinnerText} />
                  <App />
                </ErrorBoundary>
              </AppStoreProvider>
            </NetworkProvider>
          </EthersNetworkContextProvider>
        </ConnectKitProvider>
      </WagmiConfig>
    </React.Suspense>
  );
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<React.StrictMode><RenderApplication/></React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
